import type { AppRouteType } from "@/router/types";

const app: AppRouteType[] = [
    {
        path: "/app",
        name: "App",
        component: () => import("@/layouts/index.vue"),
        redirect: "/app/banner",
        sort: 4,
        meta: {
            title: "应用中心",
            icon: "Menu"
        },
        children: [
            {
                path: "banner",
                name: "AppBanner",
                component: () => import("@/views/app/banner.vue"),
                meta: {
                    title: "轮播配置",
                    icon: "Monitor"
                }
            },
            {
                path: "landing",
                name: "AppLandingIndex",
                component: () => import("@/views/app/landing.vue"),
                meta: {
                    title: "文档管理",
                    icon: "Reading"
                }
            },
            {
                path: "landingAdd",
                name: "AppLandingAdd",
                component: () => import("@/views/app/landingAdd.vue"),
                meta: {
                    title: "新增文档",
                    hidden: true
                }
            }
        ]
    }
];

export default app;
